@import '../../theme/variables.scss';

.fieldSetContainer {
  margin-bottom: 1rem;
  position: relative;
  &:last-of-type {
    margin-bottom: 0;
  }
}
.fieldSet {
  border: 1px solid rgba($color: #ffffff, $alpha: 0.2);
  transition: all ease 0.2s;
  border-radius: 4px;
  max-width: 245px;
  box-sizing: border-box;
  padding-top: 0;
  padding-bottom: 0;
  legend {
    @include indicatorBlue;
    text-align: left;
    padding: 0 0.5rem;
    transition: all ease 0.2s;
    & + .fieldSet__field {
      margin-top: 0;
    }
  }
  &__helper {
    @include indicatorGrey;
  }
  &__field {
    @include smallText;
    line-height: 22px;
    margin: 8px 0;
  }
  &:hover {
    legend {
      @include indicatorWhite;
    }
  }
  &--focus {
    border-color: $BLUE01;
    legend {
      @include indicatorWhite;
    }
  }
}

.multiselect,
.select {
  position: relative;
  &__button {
    display: block;
    position: relative;
    cursor: pointer;
    outline: 0;
    @include smallText;
    line-height: 22px !important;
    color: $DARK01;
    max-width: 100%;
    padding-right: 32px;
    &::after {
      content: '';
      position: absolute;
      right: 0px;
      width: 1rem;
      height: 1rem;
      background: none;
      background: url('../../assets/icons/arrow-bottom.svg') bottom right
        no-repeat;
    }
    &--disabled {
      cursor: default;
      color: $DARK01;
    }
    .fieldSet--focus &,
    .select--filled & {
      color: $WHITE;
    }
  }
  &__menu {
    position: absolute;
    z-index: 2;
    width: 100%;
    max-width: 115%;
    min-width: 75px;
    left: 0;
    top: 1.1rem;
    border-radius: 4px;
    max-height: 335px;
    overflow: auto;
    box-shadow: 0px 0px 10px #0000004d;
  }
  &__item {
    @include smallText;
    .bkMenu__link {
      padding: 0.5em;
      padding-left: 30px;
      position: relative;

      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        width: 1rem;
        left: 0.5rem;
        height: 1rem;
        background: none;
        top: 4px;
      }
    }

    &--selected .bkMenu__link::before {
      background: url('../../assets/icons/check-active.svg') bottom no-repeat;
    }
  }
}

.autocomplete {
  position: relative;

  &__menu {
    position: absolute;
    z-index: 2;
    left: 0.75rem;
    top: 105%;
    left: 0;
    border-radius: 4px;
    max-height: 250pxenv;
    overflow-y: auto;
    max-width: 115%;
    overflow-wrap: break-word;
    max-height: 200px;
    box-shadow: 0px 0px 10px #0000004d;
  }
  &__item {
    @include smallText;
    .bkMenu__link {
      padding: 0.5em 1em 0.5em 1em;
    }
  }
  &__input {
    width: 85%;
    line-height: 22px !important;
  }
  &__button {
    float: right;
    min-width: 22px !important;
    width: 22px !important;
    height: 22px !important;
    padding: 0 !important;

    background: $BLUE04 !important;
    &:disabled {
      background: $DARK04 !important;
    }
  }
}

.selectedValues {
  background: $DARK02;
  border-radius: 4px;
  padding: 10px;
  padding-bottom: 0;
  .filtersContainer & {
    position: absolute;
    z-index: 3;
    max-width: 115%;
    left: 0;
    top: 105%;
  }
}

.formList {
  .inlineFieldSet {
    display: flex;
    align-items: center;
  }
  .label {
    display: inline-block;
    width: 33.33333%;
  }
  .inputContainer {
    display: block;
    width: 66.66666%;
  }
} 

.formGroup {
  .inlineFieldSet {
    display: flex;
    align-items: center;
  }
  .label {
    display: inline-block;
    width: 75%;
  }
  .inputContainer {
    display: block;
    width: 25%;
  }
} 