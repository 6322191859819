@import '../../../theme/variables.scss';

$margin: 24px;
.pageContainer {
  transition: 0.5s ease all;
  padding-top: $menu-size;
  padding-bottom: 0;
  padding-right: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  &__section {
    padding: $margin;
  }
  .filtersContainer + & {
    padding-left: $filter-width;
  }
  .filtersContainer--collapsed + & {
    padding-left: $menu-size;
  }
  &__header {
    display: flex;
    border-bottom: $separator;
    margin-bottom: $margin;
    padding-bottom: 1rem;
    justify-content: space-between;
  }
  &__title {
    font-size: 2.125rem;
    line-height: 1.2;
  }
  &__titleEditor {
    display: flex;
    align-items: center;
    min-width: 33.3333%;
    .fieldSetContainer {
      display: inline-block;
      margin-left: 1rem;
      flex-grow: 2;
      .fieldSet {
        width: 100%;
        max-width: 100%;
      }
    }
  }
  &__actions {
    width: 25%;
    text-align: right;
  }
}
