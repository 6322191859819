@import '../../../theme/variables.scss';
.mainMenu {
  display: flex;
  padding: 0;
  margin: 0;
  &__item {
    list-style-type: none;
  }
  &__button {
    position: relative;
    color: $white;
    text-decoration: none;
    padding: 0 1rem !important;
    text-transform: none !important;
    font-size: 1rem !important;
    border-radius: 0 !important;
    height: $menu-size;
    &--current {
      background: $blue02 !important;
      &::after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 4px;
        bottom: 0;
        background: $blue01;
      }
    }
    &--active {
      background: $dark03 !important;
      &::after {
        display: none;
      }
    }
    & > .MuiButton-label {
      &::after {
        content: '';
        width: 10px;
        height: 6px;
        margin-left: 0.5rem;
        background: url('../../../assets/icons/arrow-bottom.svg') no-repeat;
      }
    }
  }
  &__icon {
    margin-right: 0.5rem;
  }
  &__subMenu {
    position: absolute;
    top: $menu-size;
    min-width: 220px;
    overflow-y: auto;
    max-height: 580px;
  }
}

.mainNavigation {
  flex-grow: 2;
}
