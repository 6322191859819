@import '../../../theme/variables.scss';

.ranking,
.rankingItem {
  &__header {
    margin-bottom: 0.5rem;
  }
  &__title {
    @include subTitleWhite;
    text-overflow: ellipsis;
  }
  &__legend {
    @include indicatorGrey;
    text-align: right;
    float: left;
  }
  &__content {
    max-height: 430px;
    overflow-y: auto;
    .fullScreen & {
      max-height: calc(100vh - 270px);
    }
  }
  &__contentLimit {
    max-height: 430px;
    overflow-y: visible;
    .fullScreen & {
      max-height: calc(100vh - 270px);
    }
  }
}
.rankingPercentColumn {
  justify-content: center;
}

.rankingHeaderPercentColumn {
  text-align: right;
}

.rankingItem {
  margin-bottom: 5px;
  &__title,
  &__legend {
    @include smallText;
    padding: 0.6666rem;
    background: rgba(29, 34, 38, 1);
  }
  &__title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;
  }
  &__legend {
    float: left;
    border-left: 3px solid $DARK02;
  }

  &:nth-child(2n) {
    .rankingItem__legend,
    .rankingItem__title {
      background: rgba(29, 34, 38, 0.7);
    }
  }
}

.rankingGauge {
  content: '';
  position: absolute;
  height: 2px;
  width: 0;
  bottom: 0;
  left: 0;
  transition: all ease 0.5s;
  background: $BLUE01;
}

.label {
  margin: 5px;
}