@import '../../theme/variables.scss';
.appBar {
  width: 100%;
  position: fixed;
  display: flex;
  z-index: 1;
  box-shadow: 0px 0px 10px #0000004d;
  background: transparent linear-gradient(90deg, #0c395f 0%, #0676a1 100%) 0% 0%
    no-repeat padding-box;
  line-height: $menu-size;
  vertical-align: middle;
  height: $menu-size;

  .fullScreen & {
    justify-content: space-between;
  }

  & &__darkButton {
    float: left;
    display: block;
    width: $menu-size;
    height: $menu-size;
    background: $blue02;
    text-align: center;
  }
  .bkMenu__item {
    background: $dark03;
    border-left: 4px $dark03 solid;
    min-width: 230px;
    &--active,
    &:hover {
      border-left: 4px $BLUE01 solid;
      background: $dark;
    }
  }
}
