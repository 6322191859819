@import '../../../theme/variables.scss';

.bkDark .widget,
.bkDark.fullScreen .widgetSection {
  background: $dark03;
  box-shadow: 0px 0px 10px #00000026;
}
.widget {
  transition: width ease 0.5s;
  overflow: visible;
}
.widget,
.fullScreen .widgetSection {
  height: 100%;
  width: 100%;
  padding: 1.25rem;
  border-radius: 4px;
  &__header {
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: space-between;
  }
  &__title {
    @include titleWidget;
    font-size: 1.375rem;
    line-height: 1.33333em;
    text-transform: uppercase;
  }
  &__title_ano {
    @include titleWidget;
    font-size: 1.5rem;
    line-height: 1.33333em;
  }
  &__actionsContainer {
    position: relative;
    min-width: 120px;
  }
  &__actions {
    font-size: 1.375rem;
    line-height: 1.33333em;
    list-style-type: none;
    margin: 0;
    margin-right: -10px;
    margin-top: -4px;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  &__action {
    position: relative;
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      height: 26px;
      margin-top: -16px;
      border-left: 1px solid #737373;
    }
    &:first-of-type::before {
      display: none;
    }
    .bkButton, .bkButton:hover {
      min-width: 40px;
    }
  }
  &__content {
    &--form {
      padding: 0 15%;
    }
  }
  &__footer {
    text-align: right;
    margin-top: 1rem;
  }
  &__menu {
    position: absolute;
    z-index: 1;
    right: 0;
    top: 0;
    min-width: 180px;
  }
  &--invisible {
    .bkDark & {
      background: none;
      box-shadow: none;
    }
  }
  &--editor {
    .widget__action::before {
      display: none;
    }
  }
  &--minHeight{
    min-height: 400px;
  }
}

.fullScreen {
  .widgetSection + .widgetSection {
    margin-top: 20px;
  }
}
