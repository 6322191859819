@import '../../../theme/variables.scss';

.tableContainer {
  overflow-y: auto;
  scrollbar-color: dark;
}
.circularProgress {
  margin-right: 20px;
  margin-left: 20px;
}
.tableHeader{
  padding-right: 30px;
  display: flex
}
.tableBouton{
  padding-right: 30px
}

.table {
  min-width: 100%;
  border-collapse: collapse;

  &__head,
  &__row {
    th,
    tr,
    td {
      @include smallText;
      text-align: left;
      border: none;
      border-collapse: 0;
      padding: 0.6666rem;
    }
    td {
      min-width: 10%;
      white-space: nowrap;
    }
    th.small,
    td.small {
      min-width: 1%;
    }
    tr.line-selected {
      font-weight: bold;
    }
    th .sortable {
      text-decoration-line: underline;
      text-decoration-style: dashed;
      text-decoration-color: $DARK01;
      margin-right: .5em;
      user-select: none;
    }
    th .sortable:hover {
      cursor: pointer;
      text-decoration-color: $WHITE;
    }
  }
  &__row {
    background: rgba(29, 34, 38, 1);
    border-bottom: 5px solid $DARK02;
    &:nth-child(2n) {
      background: rgba(29, 34, 38, 0.7);
    }
    &.line-selected {
      outline: 1px solid $BLUE01;
    }
  }

  &--compact {
    td {
      padding: 0.2rem;
    }

    tr {
      border-bottom: none;
    }
  }
}