@import "../../../theme/variables.scss";

.pageAnonymizationContainer {
  padding: $menu-size 0 0 $filter-width;
  .filtersContainer--collapsed + & {
    padding-left: $menu-size;
  }
}

.Title {
  font-size: 26px;
  font-weight: lighter;
}

.Subtitle {
  font-size: 24px;
  font-weight: lighter;
}

.textareaField {
  display: inline-block;
  width: 335px;
  text-align: left;
}

.nextWidget {
margin-top: 20px;
}

.resultField {
  margin-top: 15px;
}

.anonymizationWidget {
  &__header {
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: space-between;
  }
  &__Historyheader {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: space-between;
  }
  &__title {
    @include titleWidget;
    font-size: 1.375rem;
    line-height: 1.33333em;
    text-transform: uppercase;
  }
}

.anonymizationButton {
  @include buttonText;
  cursor: pointer;
  outline: none;

  height: 38px;
  margin-left: 20px;
  min-width: 40px;
  padding: 0 20px;
  border-radius: 6px;
  background: linear-gradient(0deg, #086690 0%, #048dbb 100%) 0% 0% no-repeat
    padding-box;
  border: 2px solid rgba($color: #000000, $alpha: 0);

  &:hover,
  &:active {
    background: linear-gradient(0deg, #0c3960 0%, #0a2e4d 100%) 0% 0% no-repeat
      padding-box;
  }
}
