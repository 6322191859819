@import '../../../theme/variables.scss';

.summary {
  display: flex;
  justify-content: space-between;
}
.summaryItem {
  flex-grow: 1;
  width: 20%;
  padding: 0 20px;
  border-left: 2px solid #737373;
  &:first-child {
    padding-left: 0;
    border: 0;
  }
  &:last-child {
    padding-right: 0;
  }
  &__title {
    font-size: 1.125rem;
    height: 1.5em;
    line-height: 1.2em;
    color: rgba(255, 255, 255, 0.7);
  }
  &__value {
    text-align: right;
    padding: 0.75rem 0;
    font-size: 2.125rem;
    line-height: 1.3333em;
    border-bottom: 2px solid $blue01;
  }
}
