@import '../../theme/variables.scss';

.bkMenu {
  padding: 0;
  margin: 0;
  box-shadow: 0px 0px 10px #0000004d;
  overflow-y: auto;
  .bkDark & {
    background: $dark03;
  }
  &__item {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
  &__link {
    padding: 1.125rem;
    color: $WHITE;
    display: block;
    width: 100%;
    line-height: 1.333em;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      background: $dark;
    }
  }
  &__subIcon {
    position: absolute;
    right: 1.25rem;
    top: 44%;
  }
}

.bkMenuItem {
  position: relative;
  &--small {
    .bkMenu__link {
      @include smallText;
      padding: 0.5rem;
    }
  }
}
