@import './variables.scss';

/* Track */

.container {
  &--fullPage {
    width: 100%;
    min-height: 100vh;

    .MuiAppBar-root + & {
      padding-top: $menu-size;
    }
  }
}

.bkDark {
  ::-webkit-scrollbar-track {
    background: $DARK03;
    box-shadow: inset 0 0 1px $DARK03;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $DARK02;
    border-radius: 5px;
    box-shadow: inset 0 0 0px 2px $DARK03;
    transform: all 0.5s ease;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #3a4045;
  }
  ::-webkit-scrollbar {
    width: 10px;
    padding: 2px;
  }

  scrollbar-color: $DARK02 $DARK03;
  scrollbar-width: thin;

  background: $dark;
  color: $white;
  .container {
    &--fullPage {
      background: $dark;
    }

    &--login {
      background: url('../assets/images/bg_bka100.jpg') #0c3e65 top center
        no-repeat;
      background-size: cover;
      max-width: 100vw;
      max-height: 100vh;
      overflow: hidden;
    }
  }
}
.footer {
  .container--login + & {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: url('../assets/images/broadpeak.svg') 1rem 0 no-repeat;
    background-size: 100px;
  }
  position: absolute;
  bottom: 0;
  height: 25px;
  right: 0;
  color: rgba($color: $white, $alpha: 0.7);
  font-size: 0.8125rem;
  padding: 0 1rem;
  text-align: right;
}
