@import '../../../theme/variables.scss';

.sessionDescription {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.sessionDescriptionItem {
  display: block;
  text-overflow: ellipsis;
  padding: 0 8px 8px;

  &__1 {
    width:100%;
  }
  &__2 {
    width:50%;
  }
  &__3 {
    width:33%;
  }
  &__4 {
    width:25%;
  }
  &__5 {
    width:20%;
  }
  &__6 {
    width:16%;
  }
  &__7 {
    width:14%;
  }
  &__8 {
    width:12%;
  }
  &__9 {
    width:11%;
  }
  &__10 {
    width:10%;
  }

  &:last-child {
    padding-right: 0;
    border-right: none;
  }
  &__title {
    text-align: left;
    overflow-wrap: inherit;
    height: 20px;
    line-height: 15px;
    margin-bottom: 5px;
    margin-right: 10px;
    font-size: 1.125rem;
    color: rgba(255, 255, 255, 0.7);
  }
  &__value {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 15px;
    margin-right: 10px;
    line-height: 1.3333em;
  }
}

.sessionDescriptionRowPrimary {
  display: inline-flex;
  width: 100%;
  padding: 5px;
  background-color: $DARK03;
}

.sessionDescriptionRow {
  display: inline-flex;
  width: 100%;
}

.rowSeparator {
  width: 100%;
  border-bottom: 2px solid $GREY01;
  margin-top: 15px;
  margin-bottom: 15px;
}
