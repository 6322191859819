@import '../../theme/variables.scss';

// .icon {
//   width: 18px;
//   height: 18px;
//   filter: invert(46%) sepia(1%) saturate(408%) hue-rotate(1deg) brightness(96%) contrast(84%);
// }

.icon {
  vertical-align: middle;
  width: 18px;
  height: 18px;
}

.separate {
  border-left: 1px solid $dark01;
  margin: 0 10px;
  height: 18px;
  vertical-align: top;
}

td.picto {
  width: 150px;
  height: 45px;
}