@import '../../../theme/variables.scss';

.newWidget {
  @include basicText;
  background: none;
  border-radius: 4px;
  border: $BLUE01 dashed 2px;
  outline: none;
  display: block;
  width: 100%;
  min-height: 400px;
  min-width: 400px;
  height: 100%;
  text-align: center;
  background: url('../../../assets/icons/plus-big.svg') center 40% no-repeat;
  &:hover {
    cursor: pointer;
    background: url('../../../assets/icons/plus-big-active.svg') center 40%
      no-repeat;
    background-color: $DARK02;
  }
}

.dropContainer {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  height: 100%;
  &.dropOver {
    .dropWidget {
      width: 70%;
    }
    .dropZone{
      display: block;
    }
  }
}
.dropWidget {
  width: 100%;
}
.dropZone {
  display: none;
  content: "";
  background: $BLUE04;
  border-radius: 4px;
  width: 60px;
  position: relative;
  &.after {
    float: right;
  }
}