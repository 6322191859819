@import '../../theme/variables.scss';
.collapsableBlock {
  padding: 15px;
  margin-bottom: 15px;
  background: $DARK03;
  border-radius: 4px;
  &--collapsed {
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }
  &__title {
    @include subTitleGrey;
  }
  &__action {
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
  }
  &__content {
    margin-top: 15px;
  }
}
