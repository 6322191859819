.scrollable {
  overflow-y: auto;
  display: flex;
  flex-flow: column;
  height: calc(100vh - 100px);
}

.currentFiltersList--all + .scrollable {
  max-height: calc(100vh - 360px);
}

.preset + .scrollable {
  max-height: calc(100vh - 200px);
}

.preset + .currentFiltersList--all + .scrollable {
  max-height: calc(100vh - 274px - 138px);
}

.currentFiltersList--all1 + .scrollable {
   max-height: calc(100vh - 274px);
}

.currentFiltersList--all2 + .scrollable {
   max-height: calc(100vh - 300px);
}

.currentFiltersList--all3 + .scrollable {
   max-height: calc(100vh - 350px);
}

.currentFiltersList--all4 + .scrollable {
   max-height: calc(100vh - 360px);
}