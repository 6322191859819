.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -6px) scale(0.8175) !important;
}

.bk-tabs {
  h2 {
    float: left;
  }
  .bk-tabs-close {
    float: right;
  }
  .MuiPaper-root {
    background-color: $DARK02;
    box-shadow: none;
  }
  .MuiAppBar-colorPrimary {
    background-color: $DARK03;
  }
  .MuiTab-wrapper {
    @include subTitleWhite;
    text-transform: none;
  }
  .MuiTabs-root {
    background: $DARK02;
  }
  .MuiTabs-indicator {
    background-color: $BLUE01;
    top: 0px;
  }
  .MuiTab-root.Mui-selected {
    background-color: $DARK03;
  }
  .MuiBox-root {
    background-color: $DARK03;
    padding: 40px;
  }
  .action-barre {
    text-align: right;
    margin-top: 2rem;
  }
}
