@import './layout';
@import './overrides.scss';

@import 'jvectormap.css';

body {
  font-size: 16px;
  line-height: 1.2;
  padding: 0;
  margin: 0;
}

* {
  font-family: Calibri, Arial, Sans Serif;
  box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: lighter;
  margin: 0;
  padding: 0;
  line-height: 1.33333em;
}
.appLogo {
  padding: 0.875rem 1.75rem;
}

.left {
  float: left;
}

.right {
  float: right;
}

.textField__input,
input {
  @include smallText;
  filter: none;
  padding: 0;
  width: 100%;
  background: none;
  outline: none;
  border: none;
  line-height: 18px;
  padding: 2px;

  &:disabled {
    cursor: default;
    color: $DARK01;
  }
}

input {
  &::placeholder {
    @include smallText;
    color: $DARK01;
  }

  &:focus::placeholder {
    color: $WHITE;
  }
}

.link {
  @include simpleLink;
  &--login {
    margin-top: 1rem;
    display: inline-block;
    text-decoration: none;
  }
}