@import '../../../theme/variables.scss';

.limitSelector {
  position: relative;
  &__header {
    @include smallText;
    cursor: pointer;
    margin-left: 0.5rem;
  }
  &__list {
    z-index: 1;
    position: absolute;
    right: 0;
    min-width: 120px;
  }
}
