@import '../../theme/variables.scss';

.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(29, 34, 38, 0.9);
  padding: 20vh 30vw;
}
.modal {
  border-radius: 4px;
  background: $DARK02;
  box-shadow: 0px 0px 10px #0000004d;

  &__header {
    background: $DARK03;
    vertical-align: middle;
    padding: 1rem 40px;
    position: relative;
  }
  &__closeButton {
    position: absolute;
    right: 25px;
    top: 8px;
  }
  &__title {
    @include titleWidget;
    text-transform: uppercase;
  }
  &__content {
    @include basicText;
    padding: 40px;
    .fieldSet {
      max-width: 100%;
    }
  }
  &__footer {
    margin-top: 40px;
    text-align: right;
  }
  &__popUpfooter {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
  }
}
