@import '../../../theme/variables.scss';
.bka-chart {
  .bk-tabs {
    border-top: 1px solid $DARK03;
    margin-top: 1.25rem;
    .fullScreen & {
      border: none;
      margin: 0;
    }
  }

  .pie-chart {
    width: 66.6666%;
  }
  .pieLegend {
    width: 33.3333%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &__item {
      @include smallText;
      margin: 2px 70px 2px 0;
    }
  }
}

.bk-tabs__closeButton {
  float: right;
}

.compareDates,
.split {
  @include basicText;
  &__description {
    display: inline-block;
    margin-bottom: 1rem;
  }
}

.pieContainer {
  display: flex;
  justify-content: space-between;
}
.pieLegend {
  list-style-type: none;
  padding: 0;
  margin: 0;
  &__color {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin-right: 1rem;
  }
  &__item {
    @include basicText;
    background: $DARK03;
    padding: 0.5em;
    margin-bottom: 5px;
  }
}