.bkMenuItem {
  list-style-type: none;
  padding: 0;
  margin: 0;
  .MuiListItem-root {
    overflow: visible;
    height: 100%;
    width: 100%;
  }
  &__icon {
    margin-right: 1rem;
  }
}

.disabled {
  pointer-events: none;
  opacity:0.6;
}
