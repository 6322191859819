@import '../../theme/variables.scss';

.bkButton {
  @include buttonText;
  cursor: pointer;
  outline: none;
  display: inline-block;
  height: 40px;
  min-width: 40px;
  padding: 0 20px;
  border-radius: 6px;
  background: linear-gradient(0deg, #086690 0%, #048dbb 100%) 0% 0% no-repeat
    padding-box;
  border: 2px solid rgba($color: #000000, $alpha: 0);

  &:hover,
  &:active {
    background: linear-gradient(0deg, #0c3960 0%, #0a2e4d 100%) 0% 0% no-repeat
      padding-box;
  }
  &--invisible,
  &--invisible:hover {
    background: none;
    display: inline;
    padding: 0;
    min-width: auto;
  }
  
  &--mini {
    @include indicatorWhite();
    height: 33px;
    min-width: 33px;
    border-radius: 5px;
    background: $DARK03;
    text-transform: none;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin: 1px;

    border: 1px solid rgba($color: #000000, $alpha: 0);

    &:hover,
    &:active,
    &:focus {
      background: $DARK03;
    }
    &:hover,
    &:active,
    &-current {
      border: 1px solid $BLUE01;
    }
  }
  &--input {
    visibility: hidden;
  }
  
  &--alternate {
    background: linear-gradient(0deg, #23282c 0%, #2d3236 100%) 0% 0% no-repeat
      padding-box;
    &:hover,
    &:active,
    &:focus {
      background: linear-gradient(0deg, #101112 0%, #191d21 100%) 0% 0%
        no-repeat padding-box;
    }

    &:focus {
      border: 2px solid #737373;
    }
  }
  &--delete {
    background: linear-gradient(0deg,#FF443B 0%, #fa0d01 100%) 0% 0% no-repeat
      padding-box;
    &:hover,
    &:active,
    &:focus {
      background: linear-gradient(0deg, #701e1a 0%, #701e1a 100%) 0% 0%
        no-repeat padding-box;
    }

    &:focus {
      border: 2px solid #737373;
    }
  }
  &--disabled, &:disabled {
    opacity: 0.33;
    background: linear-gradient(0deg, #086690 0%, #048dbb 100%) 0% 0% no-repeat
    padding-box;
    &:hover,
    &:active,
    &:focus {
      background: linear-gradient(0deg, #0c3960 0%, #0a2e4d 100%) 0% 0% no-repeat
      no-repeat padding-box;
    }

    &:focus {
      border: 2px solid #737373;
    }
  }
  &--noPadding {
    padding: 0;
  }
  &--icon {
    text-align: center;
    line-height: 40px;
  }
}
.buttonTd{
  background-color: #2d3236;
}
.bkButton-export{
  display: inline-block;
  margin-left: 100px;
}

.bkButton-import{
  display: flex;
  text-align: right;
  margin-left: auto;
  max-width: 180px;
  margin-bottom: 10px
}

.label-import{
  margin-top: 5px;
  max-height: 30px;
}