@import '../../theme/variables.scss';

.badge {
  background: none;
  position: relative;
  border-radius: 30px;
  border: 1px solid $DARK01;
  display: inline-block;
  max-width: 100%;
  width: fit-content;
  padding: 0.25em 32px 0.5em 0.5em;
  margin-bottom: 0.5em;
  margin-right: 0.5em;

  &__label {
    @include smallText;
    overflow-wrap: break-word;
  }
  &__closeButton {
    position: absolute;
    height: auto;
    right: 0.5rem;
    top: 0 !important;
    min-width: auto;
    height: 30px !important;
    vertical-align: middle;
    padding: 0;
    outline: none;
    img {
      display: block;
    }
  }
  &:last-of-type {
    margin-right: 0;
  }
}
