.userMenu {
  &__menu {
    position: absolute;
    left: 100%;
    top: 0;
  }
  &__header {
    position: absolute;
    width: 100%;
    right: 100%;
    top: 0;
  }
}
