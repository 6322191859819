@import '../../../theme/variables.scss';

$padding: 15px;
$separator: 1px solid #2d3236;

.filtersContainer {
  box-shadow: 0px 0px 10px #0000004d;
  width: $filter-width;
  height: 100%;
  position: fixed;
  padding-top: $menu-size;
  transition: 0.5s ease all;
  .bkDark & {
    background: $dark02;
  }

  &__header {
    padding: $padding 0;
    cursor: pointer;
    border-bottom: $separator;
    &::before {
      content: '';
      float: left;
      display: inline-block;
      width: $menu-size;
      height: 1.2rem;
      background: url('../../../assets/icons/arrow-left-active.svg') bottom
        center no-repeat;
    }
  }

  &__title {
    @include subTitleWhite;
    text-transform: uppercase;
    display: inline-block;
    text-align: center;
    width: $filter-width - ($menu-size * 2);
    &::before {
      content: '';
      display: inline-block;
      width: 18px;
      height: 1.2rem;
      margin-right: 0.5rem;
      background: url('../../../assets/icons/filter-active.svg') bottom
        no-repeat;
    }
  }
  .currentFiltersCount {
    display: inline-block;
    text-align: center;
    &__badge {
      @include indicatorWhite;
      display: inline-block;
      background: $BLUE04;
      padding: 1px 0;
      line-height: 1.125rem;
      border-radius: 2rem;
      min-width: 1.125rem;
      min-height: 1.125rem;
      margin-left: 0.5rem;
    }
  }
  &__filters {
    padding: $padding;
  }
  &--collapsed {
    width: $menu-size;
    .filtersContainer__header::before {
      content: '';
      display: inline-block;
      float: none;
      width: 100%;
      height: 1.2rem;
      margin: 0;
      background: url('../../../assets/icons/arrow-right-active.svg') bottom
        center no-repeat;
    }
    .filtersContainer__title {
      width: 100%;
      text-align: center;
      &::before {
        display: none;
      }
      &::after {
        content: '';
        display: inline-block;
        width: 18px;
        height: 23px;
        background: url('../../../assets/icons/filter-active.svg') bottom center
          no-repeat;
      }
    }
    .currentFiltersCount {
      display: block;
      &__badge {
        margin: $padding 0;
      }
    }
  }
}

.filterPresetsContainer {
  padding: $padding;
  border-bottom: $separator;
}

.presetFilterElmt {
  text-align: right;
  padding-right: 10px;
}

.currentFiltersList {
  padding: $padding;
  border-bottom: $separator;
  max-height: 165px;
  overflow-y: auto;
}

.currentFilter {
  @include smallText;
  position: relative;
  border-radius: 3px;
  padding: 0.5rem;
  margin-bottom: 5px;
  background: $BLUE04;
  overflow-wrap: break-word;
  padding-right: 28px;

  &::before {
    content: '';
    display: inline-block;
    width: 12px;
    height: 9px;
    padding-right: 0.5rem;
    background: url('../../../assets/icons/check-active.svg') bottom center
      no-repeat;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
  &__closeButton {
    position: absolute;
    height: auto !important;
    min-width: auto;
    right: 0.5rem;
    top: 10px;
    img {
      display: block;
    }
  }
}

.totalSessions {
  @include indicatorBlue;
  display: block;
  width: 45px;
  float: right;
  margin-right: 10px;
}
