@import '../../../theme/variables.scss';
.datepickerMenu {
  position: relative;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  &__button {
    width: 250px;
  }

  &__menu {
    position: absolute;
    right: 0;
  }
}
