@import '../../theme/variables.scss';

.burgerMenu {
  &__button {
    float: left;
    display: block;
    width: $menu-size;
    height: $menu-size;
    background: url('../../assets/icons/burger.svg') center center no-repeat
      $blue02 !important;
  }
  &__menu {
    position: absolute;
    top: $menu-size;
  }
}
