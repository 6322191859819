@import '../../theme/variables.scss';
.fullScreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  &__title {
    padding-left: 25px;
  }
  &__icon {
    margin-right: 0.5rem;
    vertical-align: middle;
  }
}
