.errorBlock{
  position: relative;
}

.error {
  // position: absolute;
  top: 0;
  bottom: 50px;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.errorText{
  text-align: center;
  padding: 0.75rem 0;
  font-size: 1.125rem;
  line-height: 2.3333em;
  border-bottom: 2px solid  #00b5e6;
}

// Two Error to superposate the Two div <Highchart> && <Nodata to display>
// .error1 {
//   text-align: center;
//   // position:relative; 
//   // top: 30px;
//   // margin: 0px 50px 0px 150px;
// }
 
// .error2 {
//   // position: relative;
//   top: 15px;
//   left: 20px;
//   margin: 0px 50px 0px 50px;
// }
