$white: #ffffff;

$blue01: #00b5e6;
$blue02: #092e4c;
$blue-btn-disable: #7bd7f1;
$current-blue: #00b5e6;

$red: #ff443b;

$dark: #1d2226;
$dark01: #737373;
$dark02: #13181b;
$dark03: #2d3236;

/** --- COLORS FROM UI GRUIDELINES --- */

$WHITE: #fff;
$GREY01: #e5e5e5;
$DARK01: #737373;
$DARK02: #2d3236;
$DARK03: #1d2226;
$DARK04: #13181b;
$BLUE01: #00b5e6;
$BLUE02: #1976d2;
$BLUE03: #164e7c;
$BLUE04: #0c395f;
$RED: #ff443b;

/** --- Fonts from UI Guidelines --- */

@mixin titlePage {
  font-size: 34px;
  line-height: 1.2em;
  color: $WHITE;
}
@mixin titleWidget {
  font-size: 22px;
  line-height: 1.2em;
  color: $WHITE;
}
@mixin titleFullPage {
  font-size: 20px;
  line-height: 1.2em;
  color: $WHITE;
}
@mixin subTitleWhite {
  font-size: 18px;
  line-height: 1.2em;
  color: $WHITE;
}
@mixin subTitleGrey {
  font-size: 18px;
  line-height: 1.2em;
  color: $DARK01;
}
@mixin basicText {
  font-size: 16px;
  line-height: 1.2em;
  color: $WHITE;
}
@mixin simpleLink {
  font-size: 16px;
  line-height: 1.2em;
  color: #00b5e6;
}
@mixin buttonText {
  font-size: 16px;
  line-height: 1.2em;
  color: $WHITE;
  text-transform: uppercase;
}
@mixin smallText {
  font-size: 14px !important;
  line-height: 1.2em !important;
  font-weight: normal !important;
  color: $WHITE;
}
@mixin smallTextGreen {
  font-size: 14px;
  line-height: 1.2em;
  color: #9bdb16;
}
@mixin smallTextRed {
  font-size: 14px;
  line-height: 1.2em;
  color: #ff443b;
}
@mixin indicatorWhite {
  font-size: 13px !important;
  line-height: 1.2em !important;
  color: $WHITE;
}
@mixin indicatorGrey {
  font-size: 13px;
  line-height: 1.2em;
  color: $DARK01;
}
@mixin indicatorBlue {
  font-size: 13px;
  line-height: 1.2em;
  color: #00b5e6;
}

$menu-size: 70px;
$filter-width: 290px;

$separator: 1px solid $DARK02;

.highlight {
  &--success {
    @include smallTextGreen();
  }
  &--alert {
    @include smallTextRed();
  }
}
