@import '/src/theme/mixins.scss';

.switch-input {
  .MuiSwitch-switchBase {
    color: $blue-btn-disable;
  }
  .MuiSwitch-track {
    background-color: $blue-btn-disable;
  }
  .MuiIconButton-root:hover,
  .MuiSwitch-colorSecondary.Mui-checked:hover {
    background: none;
  }
  .MuiSwitch-colorSecondary.Mui-checked {
    color: $current-blue;
  }
  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: $current-blue;
  }
  .MuiGrid-spacing-xs-1 > .MuiGrid-item {
    padding: 0;
  }
}
