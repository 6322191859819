@import './variables.scss';

.container--login {
  text-align: center;
  padding: 3rem 0;

  .loginBox {
    display: inline-block;
    width: 680px;
    padding: 2rem 8rem;
  }
}

.bkDark {
  .branding {
    display: inline-block;
    width: 100%;
    background: url('../assets/images/BkA100.png') top center no-repeat;
    background-size: 336px 120px;
    padding: 130px 0 2rem 0;
    text-align: center;
    color: rgba($color: #ffffff, $alpha: 0.7);
    font-size: 2.125rem;
    line-height: 1.2em;
    font-weight: lighter;
  }

  .loginBox {
    background: rgba($color: $dark, $alpha: 0.9);

    &__title {
      font-weight: lighter;
      margin: 0 0 3rem 0;

      &::before {
        display: inline-block;
        content: '';
        width: 1em;
        height: 1em;
        background: url('../assets/icons/person.svg') no-repeat;
        background-size: 1em 1em;
        margin-right: 0.5em;
      }
    }
    .fieldSet {
      max-width: 100%;
      input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px #1a252d inset !important;
        -webkit-text-fill-color: #ffffff !important;
      }

      &:last-of-type {
        margin-bottom: 20px;
      }
    }
  }
}
