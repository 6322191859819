@import '../../../theme/variables.scss';

.periodSelector {
  background: $DARK02;
  display: flex;
  &__zone {
    &--duration {
      width: 250px;
    }
  }
}

.periodDuration {
  padding: 0 25px 25px 25px;
  margin-bottom: 25px;
  background: $DARK03;
  &__label {
    @include basicText;
    .periodSelector__zone > & {
      padding-left: 30px;
    }
  }
}

.react-datepicker {
  @include basicText;
  background: none;
  border: none;
  margin: 0 25px;
  &__header {
    background: none;
    border: none;
  }
  &__day-names {
    margin: 25px 0;
    display: flex;
    justify-content: space-evenly;
  }
  &__week {
    display: flex;
    justify-content: space-evenly;
  }
  &__month {
    margin: 0;
  }
  &__day-name {
    @include indicatorGrey;
  }

  &__current-month {
    @include subTitleWhite;
    font-weight: normal;
  }
  &__day {
    @include basicText;
    width: 33px;
    height: 33px;
    line-height: 33px;
    border-radius: 33px;
    border: none;
    outline: none;
    &:hover,
    &--today {
      border-radius: 33px;
      background: $DARK03;
      border: none;
    }
    &--in-range {
      background: $BLUE04;
    }
    &--selected {
      background: $BLUE01;
    }
    &--disabled,
    &--outside-month {
      color: $DARK01;
    }
    &--keyboard-selected {
      background: none;
    }
  }
  &__navigation {
    &--previous {
      border: none;
      outline: none;
      top: 15px;
      left: 20px;
      padding-right: 30px;
      background: url('../../../assets//icons/arrow-left.svg') no-repeat center;
    }
    &--next {
      border: none;
      outline: none;
      top: 15px;
      right: 20px;
      padding-left: 30px;
      background: url('../../../assets//icons/arrow-right.svg') no-repeat center;
    }
  }
}

.datepicker {
  background: $DARK02;
  padding: 10px;
  box-shadow: 0px 0px 10px #00000026;
  position: absolute;
  z-index: 2;
}
